<template>
  <div class="book-list">
    <div class="search">
      <a-card :bordered="false">
        <img slot="cover" alt="example" src="~@/assets/images/pic.jpg" />
      </a-card>
      <div class="search-con">
        <a-input-group compact>
          <a-select default-value="2" @change="getChange" dropdownClassName="select-drop-down-name">
            <a-select-option value="2"> 图书 </a-select-option>
            <a-select-option value="1"> 新闻 </a-select-option>
          </a-select>
          <a-input-search
            placeholder="书名 / isbn / 作者 / 书名空格作者 / 书代号 / 责任编辑 / 策划编辑"
            enter-button="检索"
            v-model="searchValue"
            @search="getBookList"
          />
        </a-input-group>
      </div>
    </div>
    <div class="book-classify">
      <div class="container">
        <a-tabs type="card" @change="callback" v-model="firstType">
          <a-tab-pane
            v-for="item in bookTypeList"
            :key="item.id"
            :tab="item.themename"
          >
            <a-checkbox-group @change="onChangeS" v-model="secendType">
              <a-row  class="font14">
                <a-col
                  :span="3"
                  v-for="data in item.childrenList"
                  :key="data.id"
                >
                  <a-checkbox :value="data.id">
                    <a class="themen-name-ovflow" :title="data.themename">{{
                      data.themename
                    }}</a>
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
            <!-- <a-radio-group
              name="radioGroup"
              v-model="secendType"
              @change="onChange"
            >
              <a-radio  v-for="data in item.childrenList" :value="data.id" :key="data.id">
                <a class="themen-name-ovflow" :title="data.themename">{{ data.themename }}</a>
              </a-radio>
            </a-radio-group> -->
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div class="book-timeline">
      <div
        class="container"
        :class="{
          'new-container':
            bookYearlist.length > 14 || bookYearlistOld.length < 14,
        }"
      >
        <a-radio-group name="radioGroup" v-model="nowYear" @change="onChange">
          <a-radio :value="item" :key="item" v-for="item,index in bookYearlist">
            <i>{{ item }}</i>
            <span class="radio-border" v-if="index !== (bookYearlistOld.length - 1)"></span>
          </a-radio>
        </a-radio-group>
        <a
          class="more"
          v-show="bookYearlistOld.length > 13 && bookYearlist.length < 15"
          @click="showMoreYear"
          >更多年份</a
        >
      </div>
    </div>
    <div class="list-con">
      <div class="container">
        <a-list item-layout="vertical" :data-source="dataBookList">
          <a-list-item slot="renderItem" key="item.title" slot-scope="item">
            <img
              v-if="item.sysFldCoverpath"
              @click="gobookDetails(item.sysFldDoi)"
              slot="extra"
              alt=""
              :src="item.imgurl"
            />
            <img
              slot="extra"
              @click="gobookDetails(item.sysFldDoi)"
              alt=""
              v-if="!item.sysFldCoverpath"
              :src="require('@/assets/images/nobook.jpeg')"
            />
            <a-list-item-meta>
              <a slot="title" @click="gobookDetails(item.sysFldDoi)"
                ><span v-html="item.newTitle"></span
              ></a>
              <template slot="description">
                <a-descriptions :column="2">
                  <a-descriptions-item label="作者">
                    <a :title="item.author" v-html="item.authorNew"></a>
                  </a-descriptions-item>
                  <a-descriptions-item label="出版时间">
                    <a :title="item.issuedate">{{ item.issuedate }}</a>
                  </a-descriptions-item>
                  <a-descriptions-item label="ISBN" class="isbn-style">
                    <a :title="item.isbn" v-html="item.isbnNew"></a>
                  </a-descriptions-item>
                  <a-descriptions-item label="价格">
                    <a :title="item.price">{{ item.price }}</a>
                  </a-descriptions-item>
                </a-descriptions>
              </template>
            </a-list-item-meta>
            <div class="brief" v-if="item.digest">
              <span>图书简介：</span
              ><span class="digest-style" v-html="item.digest"></span>
            </div>
          </a-list-item>
        </a-list>
        <a-pagination
          show-quick-jumper
          v-if="total > 10"
          v-model="current"
          :total="total"
          :pageSize="10"
          @change="onChangePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/book.js";
import { replaceNew } from "@/utils/util";

const bookClassify = [
  "全部",
  "教材",
  "考试用书",
  "科普生活",
  "学术著作",
  "中医古籍",
  "年鉴/法规/标准",
  "工具书",
];

export default {
  name: "BookList",
  components: {},
  data() {
    return {
      bookClassify,
      bookYearlist: [],
      firstType: "",
      secendType: [],
      bookTypeList: [],
      dataBookList: [],
      current: 1,
      total: 0,
      searchValue: "",
      bookYearlistOld: [],
      nowYear: "全部",
    };
  },
  created() {
    if (this.$route.params && this.$route.params.value) {
      this.searchValue = this.$route.params.value;
    }
    if (this.$route.params && this.$route.params.parentType) {
      this.firstType = this.$route.params.parentType;
      this.secendType = this.$route.params.type ? [this.$route.params.type] : [];
    }
    //获取图书分类
    this.getBookTheme();
    //查询图书列表
    this.getBookList();
    //获取年份
    this.getYear();
  },
  methods: {
    showMoreYear() {
      this.bookYearlist = this.bookYearlistOld;
      this.bookYearlist.unshift("全部");
    },
    getYear() {
      api.getYear().then((res) => {
        if (res.data.success) {
          this.bookYearlistOld = res.data.data;
          this.bookYearlist = this.bookYearlistOld.slice(0, 13);
          this.bookYearlist.unshift("全部");
        } else {
          this.bookYearlist = [];
        }
      });
    },
    //去图书详情
    gobookDetails(doi) {
      this.$router.push({
        name: "BookDetail",
        params: {
          doi: doi,
        },
      });
    },
    onChangePage(pageNumber) {
      this.current = pageNumber;
      this.getBookList();
    },
    //查询图书
    getBookList() {
      let obj = {
        pageSize: 10,
        pageNumber: this.current,
        theme:
          this.secendType && this.secendType.length > 0
            ? this.secendType.join(",")
            : this.firstType,
        year: this.nowYear === "全部" ? "" : this.nowYear,
        query: this.searchValue,
        order: 'ISSUEDATE', //发布时间传
        sort: '0' // 0倒叙 1正序
      };
      let vm = this;
      api.getBookList(obj).then((res) => {
        if (res.data.success) {
          this.dataBookList = res.data.data.list;
          this.dataBookList.map(function (item) {
            item.newTitle = replaceNew(item.name, vm.searchValue);
            item.authorNew = replaceNew(item.author, vm.searchValue);
            item.isbnNew = replaceNew(item.isbn, vm.searchValue);
            item.imgurl =
              process.env.VUE_APP_API_BASE_URL +
              "/download?fileCode=" +
              item.sysFldCoverpath;
          });
          this.total = res.data.data.count;
        }
      });
    },
    //获取图书分类
    getBookTheme() {
      api.getBookTheme().then((res) => {
        if (res.data.success) {
          this.bookTypeList = res.data.data;
          let childrenList = []
          this.bookTypeList.map(function(item){
            if (item.childrenList) {
             childrenList.push(...item.childrenList)
            }
          })
          //全部 以后需要的话解开注释
          this.bookTypeList.unshift({
            id: "",
            themename: "全部",
            childrenList: childrenList,
          });
          if (this.$route.params && !this.$route.params.parentType) {
            this.firstType = this.bookTypeList[0].id;
          }
          // this.firstType = this.bookTypeList[0].id;
        } else {
          this.bookTypeList = [];
        }
      });
    },
    callback(key) {
      // console.log(key);
      //清空子菜单
      this.secendType = [];
      //查询图书列表
      this.getBookList();
    },
    onSearch(value) {
      // console.log(value);
    },
    onChange(e) {
      // console.log("radio1 checked", e);
      //查询图书列表
      this.getBookList();
    },
    onChangeS() {
      //查询图书列表
      this.getBookList();
    },
    getChange(key) {
      if (key === "1") {
        this.$router.push({ name: "NewsSearch",params:{value:this.searchValue} });
      }
    },
    // onChange(pageNumber) {
    //   console.log('Page: ', pageNumber);
    // },
  },
};
</script>
<style lang="less">
@import "./BookList.less";
</style>
